import axios from 'axios';

const APIUrl = 'https://api-dev.3dfitter.com:8443';
// const APIUrl = 'https://api.3dfitter.com:8444';

const instance = axios.create({
  baseURL: APIUrl,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': 'https://api-dev.3dfitter.com',
    'Content-Type': 'application/json',
  },
});

const APIMainRequestPOST = async (endPoint, getParameter) => {
  console.log('APIMainRequestPOST ->');
  try {
    const response = await instance.post(endPoint, getParameter)
    console.log('APIMainRequestPOST -> response #1 : ' + response.data.success);
    const result = {
      getData: response.data,
    };
    return result
  } catch (error) {
    console.log('APIMainRequestPOST -> Catch Request ERROR : ' + error);
    const resultError = {
      isSuccess: false,
      getData: 'ERROR',
    };
    return resultError
  }
};

const APIMainRequestGET = async (endPoint, getParameter) => {
  console.log('APIMainRequestGET ->');
  try {

    const response = await instance.get(endPoint, getParameter)
    console.log('APIMainRequestGET -> response #1 : ' + response.data);
    const result = {
      getData: response.data
    }
    return result
  } catch (error) {
    console.log('APIMainRequestGET -> Catch Request ERROR : ' + error);
    const resultError = {
      isSuccess: false,
      getData: 'ERROR',
    };
    return resultError
  }
}

const APIMainRequestPATCH = async (endPoint, getParameter) => {
  console.log('APIMainRequestPATCH ->');
  try {
    const response = await instance.patch(endPoint, getParameter)
    console.log('APIMainRequestPATCH -> response #1 : ' + response.data);
    const result = {
      getData: response.data
    }
    return result
  } catch (error) {
    console.log('APIMainRequestPATCH -> Catch Request ERROR : ' + error);
    const resultError = {
      isSuccess: false,
      getData: 'ERROR',
    };
    return resultError
  }
}

const APIMainRequestDELETE = async (endPoint, getParameter) => {
  console.log('APIMainRequestDELETE ->');
  try {
    const response = await instance.delete(endPoint, getParameter)
    console.log('APIMainRequestDELETE -> response #1 : ' + response.data);
    const result = {
      getData: response.data
    }
    return result
  } catch (error) {
    console.log('APIMainRequestDELETE -> Catch Request ERROR : ' + error);
    const resultError = {
      isSuccess: false,
      getData: 'ERROR',
    };
    return resultError
  }
}

const SimpleRequestGET = async (baseUrl, endPoint, setToken) => {
  const finalUrl = `${baseUrl}${endPoint}`
  console.log('SimpleRequestGET -> finalUrl : ' + finalUrl);
  console.log('SimpleRequestGET -> setToken : ' + setToken);
  try {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}${endPoint}`,
      headers: {
        'X-Shopify-Access-Token': setToken,
        'Content-Type': 'application/json', 
      }
    };
    //   const instance = axios.create({
    //     baseURL: baseUrl,
    //     withCredentials: false,
    //     headers: setHeader,
    // });

    await axios.request(config).then((response) => {

      console.log('SimpleRequestGET -> response #1 : ' + response.data);
      const result = {
        getData: response.data
      }
      return result
    })


  } catch (error) {
    console.log('SimpleRequestGET -> Catch Request ERROR : ' + error);
    const resultError = {
      isSuccess: false,
      getData: 'ERROR',
    };
    return resultError
  }
}

export { APIMainRequestPOST, APIMainRequestGET, APIMainRequestPATCH, APIMainRequestDELETE, SimpleRequestGET, APIUrl }