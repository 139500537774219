import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tooltip } from '@mui/material';

const DateAndTime = ({ unixTimestamp }) => {
    const [getDateAndTime, setDateAndTime] = React.useState(null);
    useEffect(() => {
        // Convertir le timestamp Unix en objet moment
        const dateObject = moment.unix(unixTimestamp);
        // Formater la date selon vos besoins
        const full = dateObject.format('YYYY/MM/DD - HH:mm:ss');
        setDateAndTime(full)
    }, [unixTimestamp]);

    return (
        <>
            {getDateAndTime !== null ? (
                <>{getDateAndTime}</>
            ) : (
                <>Chargement...</>
            )}
        </>
    );
};

const DateOnly = ({ unixTimestamp }) => {
    const [getDateOnly, setDateOnly] = useState(null);
    const [getDateAndTime, setDateAndTime] = useState(null);

    useEffect(() => {

        // Convertir le timestamp Unix en objet moment
        const dateObject = moment.unix(unixTimestamp);

        // Formater la date selon vos besoins

        const onlyDate = dateObject.format('YYYY/MM/DD');
        const onlyTime = dateObject.format('HH:mm:ss');
        const Final = onlyDate + ' - ' + onlyTime
        setDateAndTime(Final)
        setDateOnly(onlyDate)

    }, [unixTimestamp]);

    return (
        <>
            {
                getDateOnly !== null ? (
                    <><Tooltip title={getDateAndTime} arrow>
                        {getDateOnly}
                    </Tooltip>
                    </>
                ) : (
                    <>Chargement...</>
                )
            }

        </>
    );
};

const TimeOnly = ({ unixTimestamp }) => {
    const [getTimeOnly, setTimeOnly] = useState(null);
    const [getDateAndTime, setDateAndTime] = useState(null);
    useEffect(() => {

        // Convertir le timestamp Unix en objet moment
        const dateObject = moment.unix(unixTimestamp);

        // Formater la date selon vos besoins
        const onlyTime = dateObject.format('HH:mm:ss');
        const onlyDate = dateObject.format('YYYY/MM/DD');
        const Final = onlyDate + ' - ' + onlyTime
        setDateAndTime(Final)
        setTimeOnly(onlyTime)

    }, [unixTimestamp]);

    return (
        <>
            {getTimeOnly !== null ? (
                <><Tooltip title={getDateAndTime} arrow>
                    {getTimeOnly}
                </Tooltip>
                </>
            ) : (
                <>Chargement...</>
            )}
        </>
    );
};

const DateAndTineNoUI = (unixTimestamp) => {
    const [getDateAndTime, setDateAndTime] = React.useState(null);
    useEffect(() => {
        // Convertir le timestamp Unix en objet moment
        const dateObject = moment.unix(unixTimestamp);
        // Formater la date selon vos besoins
        const full = dateObject.format('YYYY/MM/DD - HH:mm:ss');
        setDateAndTime(full)
    }, [unixTimestamp]);
    return unixTimestamp !== undefined ? getDateAndTime : null;
}

export { DateAndTime, DateOnly, TimeOnly, DateAndTineNoUI };