import logo from '../Assets/logo.png';
import '../Styles/App.css';
import axios from 'axios';
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { replace, useNavigate, useSearchParams, redirect } from 'react-router-dom';
import Lasts from './Lasts.js';
import Users from './AuthUsers/Users.js';
import Account from './Account.js';
import Analytics from './Analytics.js';
import Products from './Products.js';
import Faqs from './Faqs.js';
import Plans from './Plans.js'
import { APIMainRequestGET, APIMainRequestPOST, APIUrl } from '../Manager/CommManager.js';

const Dashboard = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    // const searchParams = useSearchParams();
    const [connected, setConnected] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [getAuthUrl, setAuthUrl] = useState()
    const UseQuery = () => new URLSearchParams(window.location.search)

    
 
    useEffect(() => {
        
        // getMerchantHubShopInfo()

    }, []);

    return (
        
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    {searchParams.get("shop")}'s DASHBOARD
                </p>
                <p>
                    Automatic updater
                </p>
            </header>
        </div>
    );
};

export default Dashboard;