import * as React from 'react';
import { Avatar, Button, Link, TextField, Typography, Grid, Paper, FormControlLabel, Checkbox, Box, Stack } from '@mui/material';
import { LockOutlined, LocationCity } from '@mui/icons-material';
import { blue, grey, red } from '@mui/material/colors';
import logo from '../../Assets/logo.png';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import Signin from './Signin';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://3dfitter.com/">
                3D Fitter
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Auth = () => {

    const [whitchPage, setWhitchPage] = React.useState(<Signin/>)

    const paperStyle = { padding: 20, height: '60vh', width: '40vw', margin: "19px auto", backgroundColor: grey[50], borderRadius: 5 }
    const avatarStyle = { backgroundColor: '#D9D9D9' }
    const logoStyle = { backgroundColor: '#D9D9D9', margin: '10px 0', width: 110, height: 110 }

    const handleLogin = () => {
        console.log('Signin -> handleLogin')

    }

    const handleOnChange = e => {
        const getID = e.target.id
        const getValue = e.target.value
        console.log('Signin -> handleOnChange -> Value : ' + getValue + ' - Get ID : ' + getID)
    }


    return (

        <Grid sx={{
            backgroundImage: 'url(https://picsum.photos/600/800/)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundPosition: 'center', height: '100vh', paddingTop: 2
        }}>
            <Grid align='center'>
                <Avatar style={logoStyle} alt="3D Fitter" src={logo} />
                <Stack sx={{ bgcolor: '#ffffff6b', width: '43vw', borderRadius: 2 }}><h2>3D Fitter</h2></Stack>
            </Grid>

            {
                whitchPage
            }
            
            <Grid2 sx={{ bottom: 0, bgcolor: '#FFFFFF', height: '5vh', width: '100vw', position: 'fixed', padding: '1vh' }}>
                <Copyright />
            </Grid2>
        </Grid>

    )
}

export default Auth