import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
// import { useForm, Controller, useWatch } from 'react-hook-form';
// import { StlViewer } from "react-stl-viewer";
import { styled, useTheme } from '@mui/material/styles';
import {
    Fab, Box, useMediaQuery, Dialog, CircularProgress,
    DialogActions, DialogContent, DialogContentText, DialogTitle, 
    Button, Stack, Paper, Typography, Link, ListItemText,
    Fade, Modal, Backdrop
} from '@mui/material';
import {
    DataGrid, GridActionsCellItem, GridToolbar,
} from '@mui/x-data-grid';
import {
    CalendarMonth, BrandingWatermark, Storefront, Info, CheckCircleOutline,
    ViewInAr, AddCircle, Cancel, QrCode2, Edit, Delete, CloudDownload, Category,
    Check
} from '@mui/icons-material';

import { red, green, blue, grey } from '@mui/material/colors';


import { APIMainRequestGET, APIMainRequestDELETE } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import AddLasts from '../Components/AddLast';
import UpdateLasts from '../Components/UpdateLast';
import DetailsLasts from '../Components/DetailsLast';
import { DateAndTime, DateOnly, TimeOnly } from '../Manager/UnixTimestampToDate';


const Lasts = ({ getWitchPage }) => {
    // const navigation = useNavigate();

    const [getWidth, setWidth] = useState(window.innerWidth - 268)
    const [getHeight, setHeight] = useState(window.innerHeight)

    const [loading, setLoading] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [getDisplayInModal, setDisplayInModal] = React.useState()
    const [open, setOpen] = React.useState(false);
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

    const [getLastList, setLastList] = useState([])
    const [getCatName, setCatName] = useState('')
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const [rowNumbers, setRowNumbers] = useState()
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    // const [pageSize, setPageSize] = useState(5);
    // const [pageNumber, setPageNumber] = useState(0);
    // const [currentPage, setCurrentPage] = useState(0);


    const [idLast, setIdLast] = useState('')

    // Alert View
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [titleAlert, setTitleAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const handleAlertCloseAgree = () => {
        console.log('DialogAlert -> handleAlertCloseAgree');
        deleteLast(idLast)
        setLastList(getLastList.filter((row) => row._id !== idLast))
        setAlertIsOpen(false);
    };
    const handleAlertCloseDisagree = () => {
        console.log('DialogAlert -> handleAlertCloseDisagree');
        setAlertIsOpen(false);
    };

    const DialogAlert = ({ title, message, cancelBtn, validateBtn }) => {
        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={alertIsOpen}
                    onClose={handleAlertCloseDisagree}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title"> {title} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {message} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleAlertCloseDisagree}> {cancelBtn} </Button>
                        <Button onClick={handleAlertCloseAgree} autoFocus> {validateBtn} </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleReset = () => {
        console.log('Lasts -> handleReset -> ')
    };

    const LastListRequest = () => {
        console.log('Lasts -> LastListRequest -> ')
        console.log('Lasts -> LastListRequest -> params #1 : ' + paginationModel.page);
        if (!loading) {
            setLoading(true);

        }
        const settingsParams = {
            perPage: paginationModel.pageSize,
            page: paginationModel.page
        }
        console.log('Lasts -> LastListRequest -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = '/lasts/list/' + paginationModel.pageSize + '/' + paginationModel.page
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            // console.log('Lasts -> LastListRequest -> lastCount : ' + JSON.stringify(myJson.lasts, null, 2))

            setLastList(myJson.lasts)
            setRowNumbers(myJson.lastCount)

        }).finally(() => {
            console.log('Lasts -> LastListRequest -> Finally : ')
            setLoading(false)

        })

    }

    const [openAddLast, setOpenAddLast] = React.useState(false);
    const handleOpenAddLast = () => setOpenAddLast(true);
    const handleCloseAddLast = () => setOpenAddLast(false);

    const addLastModal = () => {
        console.log('Last -> addTicketModal ')
        handleOpenAddLast()
    }

    const closeModalRefreshList = () => {
        console.log('Last -> closeModalRefreshList - ')
        handleCloseAddLast()

        LastListRequest()
    }

    const selectDisplayInModal = (action, id) => {
        console.log('Last -> selectDisplayInModal - id : ' + id)

        if (action == 'detail') {
            setDisplayInModal(<DetailsLasts data={id} closeModalRefreshList={closeModalRefreshList} />)
            addLastModal()
        }

        if (action == 'mod') {
            setDisplayInModal(<UpdateLasts data={id} closeModalRefreshList={closeModalRefreshList} />)
            addLastModal()
        }

        if (action == 'add') {
            // setDisplayInModal(<MGModalTicket closeModalRefreshList={closeModalRefreshList} />)
            setDisplayInModal(<AddLasts closeModalRefreshList={closeModalRefreshList} />)
            addLastModal()
        }
    }

    const handleEditClick = (id) => () => {
        console.log('Last -> handleEditClick -> id : ' + id)
        getLastList.map(function (data, index) {
            if (data._id === id) {
                selectDisplayInModal('mod', getLastList[index])
            }
        })

    };

    const handleDeleteClick = (id) => () => {
        // setRows(rows.filter((row) => row.id !== id));
        console.log('LastsCatManager -> handleDeleteClick -> id : ' + id)

        setAlertIsOpen(true)
        setTitleAlert('Confirm to delete !')
        setMessageAlert('Are you sure to delete this last ?')
        setIdLast(id)


    };

    const handleDetailClick = (id) => () => {
        console.log('Last -> handleDetailClick -> id : ' + id)
        getLastList.map(function (data, index) {
            if (data._id === id) {
                console.log('Last -> handleDetailClick -> data : ' + data.brand + ' - Index : ' + index)
                console.log('Last -> handleDetailClick -> all data : ' + JSON.stringify(getLastList[index], null, 2))
                selectDisplayInModal('detail', getLastList[index])
            }

        })
        // selectDisplayInModal('detail', id)
    }

    const [rowModesModel, setRowModesModel] = React.useState({});
    const columns = [
        {
            field: 'detail',
            headerName: 'Detail',
            type: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Info />}
                        label="Details"
                        className="textPrimary"
                        onClick={handleDetailClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
        {
            field: 'sn',
            width: 150,
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <QrCode2 sx={{ color: grey[600], width: 25, height: 25 }} />
                    <ListItemText sx={{ marginTop: 0 }}>S/N</ListItemText>
                </Box>
            </>,
            renderCell: (params) => { return (<>{params.value}</>); },
        },
        {
            field: 'entryDate',
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <CalendarMonth sx={{ color: grey[600], width: 25, height: 25 }} />
                    <ListItemText sx={{ marginTop: 0 }}>Date</ListItemText>
                </Box>
            </>,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {

                return (
                    <><DateOnly unixTimestamp={params.value} /></>
                );
            },
        },
        {
            field: 'updateDate',
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <CalendarMonth sx={{ color: grey[600], width: 25, height: 25 }} />
                    <ListItemText sx={{ marginTop: 0 }}>Updated</ListItemText>
                </Box>
            </>,
            renderCell: (params) => { return (<><DateOnly unixTimestamp={params.value} /></>); },
        },
        {
            field: 'linkPly',
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <ViewInAr sx={{ color: grey[600], width: 25, height: 25 }} />
                    <ListItemText sx={{ marginTop: 0 }}>File</ListItemText>
                </Box>
            </>,
            align: 'left',
            renderCell: (params) => {

                return (
                    <>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                console.info("I'm a button.");
                            }}
                            href={params.value}
                        >
                            <CloudDownload />
                        </Link>
                    </>
                );
            },
        },
        {
            field: 'brand',
            width: 120,
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <BrandingWatermark sx={{ color: grey[600], width: 25, height: 25 }} /><br />
                    <ListItemText sx={{ marginTop: 0 }}>Brand</ListItemText>
                </Box>
            </>,
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'model',
            width: 120,
            headerName: <Box sx={{ textAlign: 'center' }}>
                <Storefront sx={{ color: grey[600], width: 25, height: 25 }} />
                <ListItemText sx={{ marginTop: 0 }}>Model</ListItemText>
            </Box>,
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'lenght',
            headerName: 'Lenght',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'width',
            headerName: 'Width',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'ballGirth',
            headerName: 'Ball Girth',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'heelGirth',
            headerName: 'Heel Girth',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'heel',
            headerName: 'Heel',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'lastLenght',
            headerName: 'Last Lenght',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'lastWidth',
            headerName: 'Last Width',
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'description',
            width: 220,
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <BrandingWatermark sx={{ color: grey[600], width: 25, height: 25 }} /><br />
                    <ListItemText sx={{ marginTop: 0 }}>Description</ListItemText>
                </Box>
            </>,
            renderCell: (params) => { return (<> {params.value} </>); },
        },
        {
            field: 'category',
            align: 'center',
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <Category sx={{ color: grey[600], width: 25, height: 25 }} /><br />
                    <ListItemText sx={{ marginTop: 0 }}>Category</ListItemText>
                </Box>
            </>,
            renderCell: (params) => {
                // const getCat = JSON.stringify(params.value, null, 2)
                // const getCat = params.value
                console.log('Last -> columns -> category -> params.value : ')
                return (
                    <>
                        {
                            params.value.name
                        }
                    </>
                );
            },
        },
        {
            field: 'active',
            headerName: <>
                <Box sx={{ textAlign: 'center' }}>
                    <Check sx={{ color: grey[600], width: 25, height: 25 }} /><br />
                    <ListItemText sx={{ marginTop: 0 }}>Active</ListItemText>
                </Box>
            </>,
            align: 'left',
            renderCell: (params) => {
                return (
                    params.value === true ? <CheckCircleOutline sx={{ color: green[600] }} /> : <Cancel sx={{ color: red[600] }} />
                );
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const initialState = {
        columns: {
            columnVisibilityModel: {

                detail: true,
                sn: true, // 1
                entryDate: false, // 2
                updateDate: false, // 3
                linkPly: true, // 4
                brand: true, // 5
                model: true, // 6
                lenght: false, // 7
                width: false, // 8
                ballGirth: false, // 9
                heelGirth: false, // 10
                heel: false, // 11
                lastLenght: false, // 12
                lastWidth: false, // 13
                description: true, // User ID 
                category: true, // Comment description
                active: true, // 14
                action: true, // 15

            }
        },
        // pagination: { paginationModel: { pageSize: 6, page: 0 } }
    };

    const handlePageChange = (params) => {
        console.log('Lasts -> handlePageChange -> params : ' + JSON.stringify(params, null, 2));
        paginationModel.page = params.page
        paginationModel.pageSize = params.pageSize
        // if (paginationModel.pageSize === params.pageSize) {
        //     paginationModel.pageSize = params.pageSize
        // }


        LastListRequest()
    };

    const handlePageSizeChange = (newPageSize) => {
        console.log('Lasts -> handlePageSizeChange -> params : ' + newPageSize);
        // setCurrentPage(1);
        // setPageNumber(1);
        // // setPageSize(newPageSize); // Reset to the first page when pageSize changes
        // setPaginationModel({
        //     pageSize: newPageSize
        // })
    };


    const returnListLast = () => {


        return (

            <Box sx={{ height: 400, width: '100%', position: 'relative', bgcolor: '#ffffff' }}>
                <DataGrid
                    rows={getLastList}
                    columns={columns}
                    getRowId={(getLastList) => getLastList._id}
                    initialState={initialState}
                    pageSizeOptions={[6]}
                    paginationModel={paginationModel}
                    rowCount={rowNumbers}
                    autoPageSize
                    paginationMode="server"
                    onPaginationModelChange={(e) => {
                        handlePageChange(e);
                    }}
                    onPageSizeChange={(e) => {
                        handlePageSizeChange(e)
                    }}
                    rowSelectionModel={rowSelectionModel}
                    keepNonExistentRowsSelected
                    slots={{
                        toolbar: GridToolbar,
                    }}
                />
            </Box>
        )
    }

    const deleteLast = (id) => {
        console.log('Last -> deleteLast -> id : ' + id)

        console.log('Last -> deleteLast -> CONFIRME -> id : ' + id)
        const updateParm = {}
        APIMainRequestDELETE('/lasts/' + id, updateParm).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Last -> deleteLast -> ' + JSON.stringify(myJson, null, 2))
            if (myJson.success == true) {
                setMessageTypeDisplayed('success')
                setMessageDisplayed(myJson.message)
                setOpen(true)

            } else {
                setMessageTypeDisplayed('error')
                setMessageDisplayed(myJson.message)
                setOpen(true)
            }
        }).finally(() => {
            LastListRequest()
            console.log('Last -> deleteLast -> Finally : ')
            setTimeout(() => {
                setOpen(false)
            }, 5000);
        })
    }

    useEffect(() => {
        console.log('Lasts -> useEffect');

        const handleResize = () => {
            setWidth(window.innerWidth - 268)
            setHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        LastListRequest()



        // return () => {
        //     window.removeEventListener('resize', handleResize)
        // }


    }, []);

    return (
        // <div>
        <Box sx={{ padding: 1, }} width={getWidth}>

            <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
            <DialogAlert title={titleAlert} message={messageAlert} cancelBtn={'Cancel'} validateBtn={'Confirm'} />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Stack direction="row" spacing={2} >
                <Typography onClick={() => { selectDisplayInModal('add', '0') }} sx={{
                    borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                    borderWidth: 1, padding: 1, borderRadius: 1,
                }} variant="h5">
                    <Fab color="primary" aria-label="add" sx={{ marginRight: 2 }}>
                        <AddCircle />
                    </Fab>
                    Add new last
                </Typography>


            </Stack>
            <Stack
                direction="row"
                sx={{ marginTop: 2 }} >
                {returnListLast()}
            </Stack>




            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAddLast}
                onClose={closeModalRefreshList}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openAddLast}>
                    <Paper >
                        {getDisplayInModal}
                    </Paper>
                </Fade>
            </Modal>

        </Box>
        // </div>
    );
};



export default Lasts;