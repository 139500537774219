import logo from '../Assets/logo.png';
import leftFoot from '../Assets/leftFoot.png';
import rightFoot from '../Assets/rightFoot.png';
import shoesLastGrey from '../Assets/shoeLastGrey.png';
import shoesLastOrange from '../Assets/shoeLastOrange.png';
import shoesLastBlue from '../Assets/shoeLastBlue.png';
import '../Styles/App.css';
import axios from 'axios';
import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react';
import { StlViewer } from "react-stl-viewer";
import { replace, useNavigate, useSearchParams, redirect } from 'react-router-dom';
import { APIMainRequestGET, APIMainRequestPOST, APIUrl } from '../Manager/CommManager.js';
import {
    ListItemText, ListItemIcon, ListItemButton, ListItem, Divider, List, Box, AccordionSummary, AccordionActions,
    Drawer, Button, ListSubheader, Collapse, Avatar, Accordion, AccordionDetails, Typography, Modal, Switch, FormControlLabel,
    Grid, Card, CardContent, CardHeader, CardMedia, CardActions, IconButton, Paper, Chip, Stack, Alert, Slide, ToggleButton, ToggleButtonGroup,
    Backdrop, Fade, CircularProgress
} from '@mui/material';
import {
    Inbox, Mail, Menu, Logout, Login, Home, People, ViewInAr, Settings, ShowChart, Inventory,
    AccountBox, PriceCheck, Store, Send, ExpandLess, ExpandMore, StarBorder, Favorite, Share, MoreVert,
    Check, Clear, Close, WarningAmberOutlined, LinkOff, Link, WarningAmber,
    BorderStyle
} from '@mui/icons-material';

import { experimentalStyled as styled } from '@mui/material';

import { blue, green, grey, orange, purple, red, yellow } from "@mui/material/colors";
import Styles from "../Styles/Styles";
import parse from 'html-react-parser'

import { storeData, getData, removeData } from '../Manager/DataManager.js';
import { formatDate } from '../Manager/Utils.js';
import ModelViewer from '../Components/ModelViewer.js';
import SnackBarManager from '../Components/SnackBarManager.js';
import UploadMerchantLast from '../Components/UploadMerchantLast.js';
import DetailsMerchantLasts from '../Components/DetailsMerchantLast.js';
import { get } from 'react-hook-form';

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: 'none'
    // color: theme.palette.text.secondary,
}));

const icon = (
    <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
        <Avatar alt="Add a new last" src={shoesLastGrey} sx={{ width: 100, height: 100 }} />
    </Paper>
);

const Products = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

    const [alignment, setAlignment] = React.useState('-');
    const handleChangeLeftRight = (event, newAlignment) => {
        console.log('Products -> handleChangeLeftRight -> newAlignment : ' + newAlignment);
        setAlignment(newAlignment);
    };

    const UseQuery = () => new URLSearchParams(window.location.search)
    const [getProductsList, setProductsList] = useState([])
    const [getIDProduct, setIDProduct] = useState([])

    const [getOurProductsList, setOurProductsList] = useState([])

    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        // console.log('Products -> handleChange -> panel : ' + JSON.stringify(panel, null, 2));
        setExpanded(isExpanded ? panel : false);
        setIDProduct(panel)
    };

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = (id) => {
        console.log('Products -> handleOpenModal -> id : ' + id);
        setOpenModal(true)
    };
    const handleCloseModal = () => {
        console.log('Products -> handleCloseModal -> id : 0');
        setOpenModal(false);
        setIDProduct()
    }

    const [openModalShowLast, setOpenModalShowLast] = React.useState(false);
    const handleOpenModalShowLast = (id) => {
        console.log('Products -> handleOpenModalShowLast -> id : ' + id);
        setOpenModalShowLast(true)
        setIDProduct(id)
    };
    const handleCloseModalShowLast = () => {
        console.log('Products -> handleCloseModalShowLast -> id : 0');
        setOpenModalShowLast(false);
        setIDProduct()
    }

    const [openMaxSizeModal, setOpenMaxSizeModal] = React.useState(false);
    const [openMessageModal, setOpenMessageModal] = React.useState('');
    const handleCloseMaxSizeModal = () => {
        setOpenMaxSizeModal(false)
    }

    // UPLOAD FILE

    const getHubShopProductList = () => {
        console.log('Products -> getHubShopProductList -> ');
        const query = UseQuery()
        const getToken = localStorage.getItem('localAccessToken') //getData('localAccessToken')
        const getMerchantShop = localStorage.getItem('localShop') //getData('localShop')
        console.log('Products -> useEffect -> getProductsList : ' + getProductsList);
        if (getToken !== null && getProductsList.length === 0) {

            console.log('Products -> useEffect -> Get getMerchantShop : ' + getMerchantShop);
            console.log('Products -> useEffect -> Get getToken : ' + getToken);
            const makeRequest = `${APIUrl}/shopify/products/?setToken=${getToken}&setShop=${getMerchantShop}`

            const settingsParams = {
                perPage: 0,
            }

            APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
                return response.getData
            }).then(function (myJson) {
                console.log('Products -> useEffect -> getProductsList : ' + JSON.stringify(myJson, null, 2))
                setProductsList(myJson.products)

            }).finally(() => {
                console.log('Products -> useEffect -> getProductsList -> Finally ->')
            })
        }

    }

    useEffect(() => {
        

        ourProductsList()

        getHubShopProductList()

    }, []);

    const ourProductsList = () => {
        const fakeParams = {
            perPage: 0,
        }
        const merchantID = localStorage.getItem('merchantID')
        console.log('Products -> ourProductsList -> Our Products -> merchantID : ' + merchantID)
        const makeProdRequest = `${APIUrl}/merchants/product/list/${merchantID}`
        APIMainRequestGET(makeProdRequest, fakeParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Products -> ourProductsList -> getOurProductsList : ' + JSON.stringify(myJson, null, 2))
            setOurProductsList(myJson)

        }).finally(() => {
            console.log('Products -> ourProductsList -> getOurProductsList -> Finally ->')
            console.log('Products -> ourProductsList -> getOurProductsList : ' + JSON.stringify(getOurProductsList, null, 2))

        })
    }

    const shearchProdId = (id) => {

        const setReturn = getOurProductsList.find((prodId) => prodId.id === `${id}`) !== undefined
        return setReturn

    }

    const ImageProducts = (product) => {
        const { image } = product;
        const imgLink = image !== null ? image.src : 'https://picsum.photos/200'
        const imgAlt = image !== null ? image.alt : product.title
        return (
            <>
                <CardMedia
                    component="img"
                    sx={{ width: '7vw', height: '7vw' }}
                    image={imgLink}
                    alt={imgAlt}
                />
            </>
        )
    }

    const productStatus = (product) => {
        const { status, id } = product;
        const statusProd = status === 'active' ? <Chip label={status} variant="outlined" color="success" icon={<Check sx={{ color: green[800] }} />} />
            : <Chip label={status} variant="outlined" color="error" icon={<Clear sx={{ color: red[800] }} />} />

        const checkLast = shearchProdId(id) === true ? <Chip label="Last associated" variant="outlined" color="success" icon={<Check sx={{ color: green[800] }} />} />
            : <Chip label="No last associated" variant="outlined" color="warning" icon={<WarningAmber sx={{ color: orange[600] }} />} />
        return (
            <>
                <Stack direction="row" spacing={2} >
                    {checkLast}
                    {statusProd}
                </Stack>

            </>
        )
    }

    const prodVariant = (product, short) => {
        const { variants } = product
        const firstNItems = variants.slice(0, 5);
        return (
            short === true ?
                firstNItems.map((item) =>
                    <><Chip label={item.title} sx={{ margin: 1 }} variant="outlined" /></>
                )
                :
                variants.map((item) =>
                    <><Chip label={item.title} sx={{ margin: 1 }} variant="outlined" /></>
                )
        )
    }

    const htmlIntertpretor = (code) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: code }} />
        )
    }

    const closeModalRefreshList = () => {
        console.log('Products -> closeModalRefreshList - ')
        handleCloseModal()
        handleCloseModalShowLast()

        ourProductsList()
        getHubShopProductList()
    }

    const productList = () => {

        return (
            <>
                <Box >

                    <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
                    {
                        getProductsList.map((item) =>
                            <>
                                <Accordion expanded={expanded === item.id} onChange={handleChange(item)} sx={{ margin: 1, width: '80vw' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1bh-content"
                                        id={item.id}
                                    >
                                        {ImageProducts(item)}
                                        <Typography sx={{ width: '30vw', flexShrink: 0, margin: 2 }}>
                                            <b>{item.title}</b><br />
                                            {prodVariant(item, true)}

                                        </Typography>
                                        <Typography sx={{ width: '20vw', flexShrink: 0, margin: 2 }}>
                                            <AccordionActions>
                                                {
                                                    shearchProdId(item.id) === false ?
                                                        <>
                                                            <Button variant="outlined" color="warning"
                                                                onClick={handleOpenModal}
                                                                sx={{ width: '100%' }}
                                                                startIcon={<Avatar alt="Add a new last" src={shoesLastOrange} sx={{ color: orange[700] }} />}>
                                                                Associate a last
                                                            </Button>
                                                        </> : <>
                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => { handleOpenModalShowLast(item) }}
                                                                sx={{ width: '100%' }}
                                                                startIcon={<Avatar alt="Add a new last" src={shoesLastBlue} sx={{ color: grey[700] }} />}>
                                                                Detail of last
                                                            </Button>
                                                        </>

                                                }
                                            </AccordionActions>

                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', margin: 2, right: '2vw', position: 'absolute' }}>
                                            Created : {formatDate(item.created_at, "date")}
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', margin: 2, right: '2vw', bottom: '1vh', position: 'absolute' }}>

                                            {productStatus(item)}
                                        </Typography>
                                        {/* <AccordionActions>
                                            <Button>Cancel</Button>
                                            <Button>Agree</Button>
                                        </AccordionActions> */}
                                    </AccordionSummary >
                                    <AccordionDetails>
                                        <Typography>
                                            <b>Description : </b><br />
                                            <p>{htmlIntertpretor(item.body_html)}</p>
                                        </Typography>
                                        <Typography>
                                            <b>Varaints : </b><br />
                                            {prodVariant(item, false)}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )
                    }
                    {/* </Grid > */}
                </Box >


                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute', top: '5vh', left: '10vw', width: '80vw',
                            height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4,
                        }}>
                        <UploadMerchantLast whichProduct={getIDProduct} closeModalRefreshList={closeModalRefreshList} />

                    </Box>
                </Modal>

                <Modal
                    open={openModalShowLast}
                    onClose={handleCloseModalShowLast}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute', top: '5vh', left: '10vw', width: '80vw',
                            height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4,
                        }}>
                        <DetailsMerchantLasts whichProduct={getIDProduct} closeModalRefreshList={closeModalRefreshList}/>

                    </Box>
                </Modal>



            </>
        )

    }

    return (
        <Box>
            {productList()}
        </Box>
    );
};

const styleMaxSizeModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};



export default Products;