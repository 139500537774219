import logo from '../Assets/logo.png';
import '../Styles/App.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Plans = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Plans
                </p>
            </header>
        </div>
    );
};

export default Plans;