import './Styles/App.css';
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Router, Route, Routes, Navigate, createBrowserRouter, RouterProvider, BrowserRouter, HashRouter, useSearchParams } from 'react-router-dom';


import AuthProvider from './Manager/authProvider.js';
import { Stack, Paper, Divider, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import Header from './Components/Header.js';
import NavBar from './Components/NavBar.js';
import Home from './pages/Home.js'
import Dashboard from './pages/Dashboard.js';
import Lasts from './pages/Lasts.js';
import Users from './pages/AuthUsers/Users.js';
import Account from './pages/Account.js';
import Analytics from './pages/Analytics.js';
import Products from './pages/Products.js';
import Faqs from './pages/Faqs.js';
import Plans from './pages/Plans.js'
import Signin from './pages/AuthUsers/Signin.js';
import GotTo404 from './pages/GoTo404.js';
import AuthCallback from './pages/AuthCallback.js';

import { Permission } from './Manager/Utils.js';

const App = () => {

  useEffect(() => {

    const test = Permission.Admin
    console.log('App -> useEffect -> UsersProfiles : ' + test);

  }, []);

  return (
    // <BrowserRouter>


    // <HashRouter>
    // <Router>
    // <RouterProvider>
      <Routes>
        <Route path='*' element={<GotTo404 />} />
        <Route path="/" element={<Home />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/products" element={<Products />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/account" element={<Account />} />
        <Route path='/faqs' element={<Faqs />} />
        <Route path="/users" element={<Users />} />
        <Route path="/lasts" element={<Lasts />} />
        <Route path='/auth/callback' element={<AuthCallback />} />
      </Routes>
    // </RouterProvider>
    // </Router>
    // </HashRouter>

    // </BrowserRouter>

  )
};
export default App;

