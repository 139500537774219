import React from 'react';

const Permission = Object.freeze({
    Users: 'user',
    Merchants: 'merchant',
    Admin: 'admin'
})

const formatDate = (isoDate, wichFormat) => {
    // Wich format :
    // -> date return only date
    // -> time return only hours and minutes
    // -> all return date and time
    const date = new Date(isoDate);
    switch (wichFormat) {
        case "date":
            return date.toLocaleString('en-EN', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        case "time":
            return date.toLocaleString('en-EN', {
                hour: 'numeric',
                minute: 'numeric',
            });
        case "all":
            return date.toLocaleString('en-EN', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });

        default:
            return date.toLocaleString('en-EN', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });
    }
};

export { Permission, formatDate }