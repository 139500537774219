import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled } from '@mui/material/styles';
import {
    Accordion, AccordionActions, AccordionSummary, AccordionDetails,
    InputLabel, TextField, Box, MenuItem, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, Button, Stack, Paper, Typography, Slide, CircularProgress,
    Fade, Modal, Backdrop, Stepper, Step, StepLabel, StepContent
} from '@mui/material';

import {
    ExitToApp, ImageOutlined, PictureAsPdfOutlined, ViewInAr,
    WarningAmberOutlined, Folder, CheckOutlined, Close, ExpandMore,
    ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIMainRequestPOST, APIMainRequestGET, APIMainRequestPATCH } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateLasts = ({ data, closeModalRefreshList }) => {

    const [loading, setLoading] = React.useState(false);

    const [getID, setID] = useState('')
    const [getCatList, setCatList] = useState([])

    const [openModal, setOpenModal] = React.useState(false);
    const [getRawFile, setRawFile] = useState()

    const [getLastCatEntryDate, setLastCatEntryDate] = useState()
    const [getLastCatUpdateDate, setLastCatUpdateDate] = useState()
    const [getLastCatName, setLastCatName] = useState()
    const [getLastCatDesc, setLastCatDesc] = useState()
    const [getLastCatActive, setLastCatActive] = useState()

    const [storeData, setData] = useState({
        sn: '',
        brand: '',
        model: '',
        gender: '',
        getLenght: '',
        getWidth: '',
        ballGirth: '',
        heelGirth: '',
        heel: '',
        lastLenght: '',
        lastWidth: '',
        description: '',
        active: true,
        linkFile: '',

    })

    const [storeDataError, setDataError] = useState({
        snError: false,
        brandError: false,
        modelError: false,
        genderError: false,
        getLenghtError: false,
        getWidthError: false,
        ballGirthError: false,
        heelGirthError: false,
        heelError: false,
        lastLenghtError: false,
        lastWidthError: false,
        descriptionError: false,
    })

    const [getFile, setFile] = useState()
    const [getFileName, setFileName] = useState()
    const [getFileType, setFileType] = useState()

    const [open, setOpen] = React.useState(false);
    const [messageTitleDisplayed, setMessageTitleDisplayed] = useState('')
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

    const [tmpStlUrl, setTmpStlUrl] = useState()
    const regex = /^[a-zA-Z0-9-_]+$/;
    const regex1 = /^[a-zA-Z0-9-_ ]+$/;
    const regexNumber = /^[0-9.]+$/;

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleReset = () => {
        console.log('UpdateLasts -> handleReset -> ')
        // requestToSTLFile(sn)
        closeModalRefreshList()
    };

    const lastCatListRequest = () => {

        const settingsParams = {
            clientID: '',
        }
        APIMainRequestGET('/lasts_cat/list', settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('UpdateLasts -> list category -> ' + JSON.stringify(myJson, null, 2))
            setCatList(myJson)

        }).finally(() => {
            console.log('UpdateLasts -> list category -> Finally : ')
        })

    }

    const setCurrentData = (data) => {
        setData({
            sn: data.sn,
            brand: data.brand,
            model: data.model,
            gender: data.gender,
            getLenght: data.lenght,
            getWidth: data.width,
            ballGirth: data.ballGirth,
            heelGirth: data.heelGirth,
            heel: data.heel,
            lastLenght: data.lastLenght,
            lastWidth: data.lastWidth,
            description: data.description,
            active: data.active,
            linkFile: data.linkPly,

        })
        setFileName(data.jointFileName)
        setFileType(data.joinFileType)
        setFile(data.linkPly)
    }



    const sendRequest = () => {
        console.log('UpdateLasts -> sendRequest -> S/N : ' + storeData.sn);
        if (!loading) {
            setLoading(true);

        }
        const updateParm = {
            sn: storeData.sn,
            linkPly: '',
            brand: storeData.brand,
            model: storeData.model,
            gender: storeData.gender,
            lenght: storeData.getLenght,
            width: storeData.getWidth,
            ballGirth: storeData.ballGirth,
            heelGirth: storeData.heelGirth,
            heel: storeData.heel,
            lastLenght: storeData.lastLenght,
            lastWidth: storeData.lastWidth,
            description: storeData.description,
            active: true,
            category: {
                entryDate: getLastCatEntryDate,
                updateDate: getLastCatUpdateDate,
                name: getLastCatName,
                description: getLastCatDesc,
                active: getLastCatActive
            },
            jointFileName: getFileName,
            joinFileType: getFileType,
        }

        console.log('UpdateLasts -> handleSendLast -> updateParm : ' + JSON.stringify(updateParm, null, 2));
        APIMainRequestPATCH('/lasts/' + getID, updateParm).then(function (response) {
            return response.getData
        }).then(function (myJson) {

            console.log('UpdateLasts -> handleSendLast -> Add new last -> ' + JSON.stringify(myJson, null, 2))
            if (myJson.success == true) {
                setMessageTypeDisplayed('success')
                setMessageDisplayed('Your last as been uploaded !')
                setOpen(true)


            } else {
                setMessageTypeDisplayed('error')
                setMessageDisplayed('Error during your lasst uploaded process')
                setOpen(true)
            }


        }).finally(() => {
            setLoading(false);

            console.log('UpdateLasts -> handleSendLast -> Add new last -> Finally : ')
            setTimeout(() => {

                setOpen(false)
                handleReset()

            }, 5000);
        })


    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];


        setRawFile(file)
        const fileName = file.name
        const fileType = fileName.split('.').pop()
        const fileData = file.value
        if (file) {

            const maxSizeInBytes = (1024 * 1024) * 10; // 10MB (exemple : you can adjust this to suit your needs)
            if (file.size > maxSizeInBytes) {
                setOpenModal(true)
                // You can add other actions here, such as preventing the form from being sent, etc.
            } else {
                console.log('UpdateLasts -> handleFileChange -> file : ' + JSON.stringify(file, null, 2))
                const reader = new FileReader();
                // Read the file as binary data
                reader.readAsBinaryString(file);
                // reader.readAsDataURL(file)
                reader.onloadend = () => {
                    console.log('UpdateLasts -> handleFileChange -> fileData : ' + reader)
                    console.log('UpdateLasts -> handleFileChange -> Name : ' + fileName);
                    console.log('UpdateLasts -> handleFileChange -> Type : ' + fileType);
                    // Convert binary data to Base64
                    const base64Data = btoa(reader.result);
                    // setFile(base64Data);
                    setFile(file)
                    // console.log('Lasts -> handleFileChange -> Data : ' + base64Data);
                    setFileName(fileName)
                    setFileType(fileType)


                    // readStlFile(base64Data, fileName, 'model/stl')
                };
                // reader.readAsBinaryString(file);
            }

        }
    };

    const requestToSTLFile = (sn) => {
        const updateParm = {
            sn: sn,

        }
        APIMainRequestPOST('/PFLasts/get', updateParm).then(function (response) {
            return response.getData
        }).then(function (myJson) {

            console.log('UpdateLasts -> requestToSTLFile -> Get last -> ' + JSON.stringify(myJson, null, 2))
            if (myJson.success == true) {

                const lastData = myJson.lastInfo.joinFileB64
                const lastName = myJson.lastInfo.jointFileName
                const lastType = myJson.lastInfo.joinFileType
                console.log('UpdateLasts -> requestToSTLFile -> Get last Data -> ' + lastData.type + ' - Data ' + lastData.data)
                // readStlFile(lastData.data, 'myLast', 'my')

                setTmpStlUrl(lastData.data)
            } else {

            }


        }).finally(() => {

            console.log('UpdateLasts -> requestToSTLFile -> Add new last -> Finally : ')

        })
    }

    const readStlFile = (data, fileName, mimeType) => {

        // model/stl - model/x.stl-ascii - model/x.stl-binary

        // FOR IMAGE
        const blob = new Blob([Int8Array.from(data)], { type: 'application/vnd.ms-pki.stl' });
        // const blob = new Blob([Int8Array.from(getDate)], { type: getType });
        console.log('Lasts -> readStlFile -> urlToFile -> ' + window.URL.createObjectURL(blob))
        const fileURLi = window.URL.createObjectURL(blob)
        console.log('Lasts -> readStlFile -> fileURLi -> ' + fileURLi)

        //Create a Blob from the PDF Stream
        const file = new Blob([data], { type: "application/vnd.ms-pki.stl" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        console.log('Lasts -> readStlFile -> fileURL -> ' + fileURL)
        // setTmpStlUrl(fileURL)


    }

    const handleOnChange = e => {
        // setData({ ...storeData, [e.target.id]: e.target.value })
        const getID = e.target.id
        if (getID == 'sn') {
            if (regex.test(e.target.value)) {
                storeData.sn = e.target.value
                setDataError({ ...storeDataError.snError, snError: false })
            } else { setDataError({ ...storeDataError.snError, snError: 'Serial Number must contain only 0-9, a-z, A-z, "-" and "_" !' }) }
        }
        if (getID == 'brand') {
            if (regex1.test(e.target.value)) {
                storeData.brand = e.target.value
                setDataError({ ...storeDataError.brandError, brandError: false })
            } else { setDataError({ ...storeDataError.brandError, brandError: 'Brand name must contain only 0-9, a-z, A-z, "-", "_" ans space !' }) }
        }
        if (getID == 'model') {
            if (regex1.test(e.target.value)) {
                storeData.model = e.target.value
                setDataError({ ...storeDataError.modelError, modelError: false })
            } else { setDataError({ ...storeDataError.modelError, modelError: 'Model name must contain only 0-9, a-z, A-z, "-", "_" ans space !' }) }
        }
        if (getID == 'gender') {
            if (regex.test(e.target.value)) {
                storeData.gender = e.target.value
                setDataError({ ...storeDataError.genderError, genderError: false })
            } else { setDataError({ ...storeDataError.genderError, genderError: 'Gender name must contain only 0-9, a-z, A-z, "-" and "_" !' }) }
        }
        if (getID == 'getLenght') {
            if (regexNumber.test(e.target.value)) {
                storeData.getLenght = e.target.value
                setDataError({ ...storeDataError.getLenghtError, getLenghtError: false })
            } else { setDataError({ ...storeDataError.getLenghtError, getLenghtError: 'The lenght must contain only numbers and "." !' }) }
        }
        if (getID == 'getWidth') {
            if (regexNumber.test(e.target.value)) {
                storeData.getWidth = e.target.value
                setDataError({ ...storeDataError.getWidthError, getWidthError: false })
            } else { setDataError({ ...storeDataError.getWidthError, getWidthError: 'The width measure must contain only numbers and "." !' }) }
        }
        if (getID === 'ballGirth') {
            if (regexNumber.test(e.target.value)) {
                setDataError({ ...storeDataError.ballGirthError, ballGirthError: false })
                storeData.ballGirth = e.target.value
            } else { setDataError({ ...storeDataError.ballGirthError, ballGirthError: 'The ball start measure must contain only numbers and "." !' }) }
        }
        if (getID == 'heelGirth') {
            if (regexNumber.test(e.target.value)) {
                setDataError({ ...storeDataError.heelGirthError, heelGirthError: false })
                storeData.heelGirth = e.target.value
            } else { setDataError({ ...storeDataError.heelGirthError, heelGirthError: 'The heel start must contain only numbers and "." !' }) }
        }
        if (getID == 'heel') {
            if (regexNumber.test(e.target.value)) {
                setDataError({ ...storeDataError.heelError, heelError: false })
                storeData.heel = e.target.value
            } else { setDataError({ ...storeDataError.heelError, heelError: 'Heel measure must contain only numbers and "." !' }) }
        }
        if (getID == 'lastLenght') {
            if (regexNumber.test(e.target.value)) {
                setDataError({ ...storeDataError.lastLenghtError, lastLenghtError: false })
                storeData.lastLenght = e.target.value
            } else { setDataError({ ...storeDataError.lastLenghtError, lastLenghtError: 'The last lenght must contain only numbers and "." !' }) }
        }
        if (getID == 'lastWidth') {
            if (regexNumber.test(e.target.value)) {
                setDataError({ ...storeDataError.lastWidthError, lastWidthError: false })
                storeData.lastWidth = e.target.value
            } else { setDataError({ ...storeDataError.lastWidthError, lastWidthError: 'The last width must contain only numbers and "." !' }) }
        }
        if (getID == 'description') {
            storeData.description = e.target.value
        }
    }
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseAddLast = () => setOpenDialog(false);

    const handleSelectLastCat = (e) => {
        const getCat = e.target.value
        console.log('UpdateLasts -> handleSelectLastCat -> Cat selected : ' + getCat);
        setLastCatEntryDate(getCatList[getCat].entryDate)
        setLastCatUpdateDate(getCatList[getCat].updateDate)
        setLastCatName(getCatList[getCat].name)
        setLastCatDesc(getCatList[getCat].description)
        setLastCatActive(getCatList[getCat].active)
        console.log('UpdateLasts -> handleSelectLastCat -> Cat selected : ' + getCatList[getCat].name);
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (storeDataError.snError ||
            storeDataError.brandError ||
            storeDataError.modelError ||
            storeDataError.genderError ||
            storeDataError.getLenghtError ||
            storeDataError.getWidthError ||
            storeDataError.ballGirthError ||
            storeDataError.heelGirthError ||
            storeDataError.heelError ||
            storeDataError.lastLenghtError ||
            storeDataError.lastWidthError) {

            console.log('UpdateLasts -> handleSubmit -> Some error in form');
            setMessageTitleDisplayed('Form error !')
            setMessageDisplayed('Form is invalid! Please check the fields...')
            handleOpenDialog()

        } else if (getFile == null || getFileName == null || getFileType == null) {
            setMessageTitleDisplayed('File error !')
            setMessageDisplayed('A .STL file must be upload !')
            handleOpenDialog()
        } else {

            console.log('UpdateLasts -> handleSubmit -> FORM VALIDATE');
            sendRequest()

        }
    };

    useEffect(() => {
        console.log('UpdateLasts -> useEffect');
        console.log('UpdateLasts -> useEffect -> Data : ' + data._id);
        setID(data._id)
        lastCatListRequest()
        setCurrentData(data)

    }, []);

    return (
        <Paper sx={styleAddLast}>
            <Box sx={{ flexGrow: 1, flexDirection: 'row', paddingTop: 5, paddingLeft: 5, paddingRight: 5, minWidth: 500 }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
                <Close style={{ position: 'fixed', top: 1, right: 1, width: 50, height: 50, color: "#FFF" }}
                    onClick={() => { closeModalRefreshList() }} />

                <Typography sx={{
                    borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                    borderWidth: 1, padding: 1, borderRadius: 1
                }} variant="h5"><ViewInAr /> Update this last</Typography>
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Box component="form" onSubmit={handleSubmit} noValidate>

                    <Typography>
                        Please complete all imformations, select your last file (.ply or .stl).<br />
                        And clic on send button.
                    </Typography>

                    <Grid container sx={{ marginTop: 2 }}>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="S/N"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Serial Number'
                                id="sn"
                                onChange={handleOnChange}
                                error={storeDataError.snError}
                                helperText={storeDataError.snError}
                                defaultValue={data.sn}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Brand"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Brand name'
                                id="brand"
                                onChange={handleOnChange}
                                error={storeDataError.brandError}
                                helperText={storeDataError.brandError}
                                defaultValue={data.brand}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Model"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Model name'
                                id="model"
                                onChange={handleOnChange}
                                error={storeDataError.modelError}
                                helperText={storeDataError.modelError}
                                defaultValue={data.model}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Gender"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Gender name'
                                id="gender"
                                onChange={handleOnChange}
                                error={storeDataError.genderError}
                                helperText={storeDataError.genderError}
                                defaultValue={data.gender}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Lenght"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Lenght value'
                                id="getLenght"
                                onChange={handleOnChange}
                                error={storeDataError.getLenghtError}
                                helperText={storeDataError.getLenghtError}
                                defaultValue={data.lenght}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Width"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Width value'
                                id="getWidth"
                                onChange={handleOnChange}
                                error={storeDataError.getWidthError}
                                helperText={storeDataError.getWidthError}
                                defaultValue={data.width}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Ball Girth"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Ball girth value'
                                id="ballGirth"
                                onChange={handleOnChange}
                                error={storeDataError.ballGirthError}
                                helperText={storeDataError.ballGirthError}
                                defaultValue={data.ballGirth}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Heel Girth"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Heel girth value'
                                id="heelGirth"
                                onChange={handleOnChange}
                                error={storeDataError.heelGirthError}
                                helperText={storeDataError.heelGirthError}
                                defaultValue={data.heelGirth}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Heel"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Heel value'
                                id="heel"
                                onChange={handleOnChange}
                                error={storeDataError.heelError}
                                helperText={storeDataError.heelError}
                                defaultValue={data.heel}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Last lenght"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Last lengh value'
                                id="lastLenght"
                                onChange={handleOnChange}
                                error={storeDataError.lastLenghtError}
                                helperText={storeDataError.lastLenghtError}
                                defaultValue={data.lastLenght}
                            />
                        </Grid>
                        <Grid sx={{ minWidth: 450, width: '100%', padding: 1 }}>
                            <TextField
                                label="Last width"
                                sx={{ minWidth: 400, width: '100%' }}
                                placeholder='Last width value'
                                id="lastWidth"
                                onChange={handleOnChange}
                                error={storeDataError.lastWidthError}
                                helperText={storeDataError.lastWidthError}
                                defaultValue={data.lastWidth}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="column" spacing={2} sx={{ marginTop: 1 }} >
                        <FormControl fullWidth>
                            <InputLabel id="last-cat" sx={{ color: blue[700] }}>Select a category</InputLabel>
                            <Select
                                sx={{ color: blue[700] }}
                                labelId="last-cat"
                                id="last-cat-select"
                                value={getLastCatName}
                                label="Select a category"
                                onChange={handleSelectLastCat}
                                defaultValue={data.category.name}
                            >
                                <MenuItem value={data.category.name} selected>{data.category.name}</MenuItem>
                                {
                                    getCatList.map(function (data, index) {
                                        return (<MenuItem key={data._id} value={index} disabled={!data.active} >{data.name}</MenuItem>)
                                    })
                                }
                            </Select>
                        </FormControl>

                        <TextField
                            label="Description"
                            multiline
                            rows={4}
                            sx={{ minWidth: 400, width: '100%' }}
                            placeholder="Description or more information"
                            id="description"
                            onChange={handleOnChange}
                            defaultValue={data.description}
                        />

                        <Stack direction="row" spacing={2} >

                            <Stack direction="column" spacing={2} >

                                <input
                                    style={{ display: 'none' }}
                                    placeholder='You can attach a document'
                                    type="file"
                                    onChange={handleFileChange}
                                    accept=".ply, .stl"
                                    id="contained-button-file" />

                                <label htmlFor="contained-button-file">
                                    <Button
                                        variant="contained"
                                        //color="primary"
                                        component="span"
                                        sx={{
                                            // mt: 1, mr: 1,
                                            backgroundColor: blue[700], color: blue[50]
                                        }}
                                        startIcon={<FilePresentOutlined />}>
                                        Joint your file
                                    </Button>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        Accepted format : .ply, .stl
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        Max size : 10 Mo
                                    </Typography>
                                </label>

                            </Stack>

                            <Stack direction="row" spacing={2} style={{ marginTop: 15 }} >
                                {
                                    getFileType === 'stl' || getFileType === 'ply' ?
                                        <ViewInAr sx={{
                                            width: 35,
                                            height: 35,
                                            color: blue[700],
                                        }} /> : null
                                }
                                <Typography variant="h5">{getFileName}</Typography>
                            </Stack>

                            {/* {displayFileSelected(getFileType, getFileName)} */}

                        </Stack>

                    </Stack>

                    <Button
                        sx={{
                            mt: 1, mr: 1,
                            borderColor: blue[800], borderStyle: 'solid',
                            borderWidth: 1, backgroundColor: grey[50], color: blue[600]
                        }}
                        type="submit"
                        variant="contained"
                        size="large">
                        Update
                    </Button>

                    <Button
                        sx={{
                            mt: 1, mr: 1,
                            borderColor: red[700], borderStyle: 'solid',
                            borderWidth: 1, backgroundColor: grey[50], color: red[700]
                        }}
                        onClick={handleReset}
                        variant="contained"
                        size="large">
                        Cancel
                    </Button>
                </Box>


                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openModal}>
                        <Box sx={styleModal}>
                            <Close
                                style={{ position: 'absolute', top: '2%', right: '2%', width: '3vw', height: '3vw', color: blue[700] }}
                                onClick={() => { handleCloseModal() }} />

                            <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >
                                <WarningAmberOutlined sx={{
                                    width: '100%',
                                    height: 65,
                                    color: red[700],
                                }} />
                                <Typography id="transition-modal-title" variant="h3" component="h2">
                                    Attention
                                </Typography>
                                <Typography id="transition-modal-title" variant="body1" component="h2">
                                    The max size autorized is 10 Mo
                                </Typography>
                                <Button
                                    onClick={handleCloseModal}
                                    sx={{
                                        mt: 1, mr: 1,
                                        backgroundColor: blue[700], color: grey[50],
                                        width: '50%',
                                        alignSelf: 'center'
                                    }}>
                                    OK
                                </Button>
                            </Stack>
                        </Box>
                    </Fade>
                </Modal>

                <React.Fragment>
                    <Dialog
                        open={openDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseAddLast}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{messageTitleDisplayed}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {messageDisplayed}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button> */}
                            <Button onClick={handleCloseAddLast}>OK</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>

            </Box>
        </Paper>
    );
};
const styleAddLast = {
    position: 'absolute',
    top: '5%',
    left: '15%',
    // transform: 'translate(-50%, -50%)',
    width: '70%',
    minWidth: 500,
    height: '88%',
    bgcolor: 'background.paper',
    padding: 1,
    overflow: 'auto',
};

const styleModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};

export default UpdateLasts;