import React, { useEffect, useState, useRef } from 'react';
import '../Styles/App.css'
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Environment, ContactShadows } from '@react-three/drei';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { BufferGeometry, MeshStandardMaterial, Mesh, Box3, Vector3 } from 'three';
import { Box, Stack } from '@mui/material';

const ModelViewer = ({ file, fileType }) => {
    const [model, setModel] = useState(null);
    const modelRef = useRef();


    useEffect(() => {

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const contents = e.target.result;

                let geometry;
                let material;
                let mesh;

                switch (fileType) {
                    case 'stl':
                        const stlLoader = new STLLoader();
                        geometry = stlLoader.parse(contents);
                        material = new MeshStandardMaterial({ color: 0x606060 });
                        mesh = new Mesh(geometry, material);
                        break;

                    case 'ply':
                        const plyLoader = new PLYLoader();
                        geometry = plyLoader.parse(contents);
                        material = new MeshStandardMaterial({ color: 0x606060 });
                        mesh = new Mesh(geometry, material);
                        break;

                    case 'obj':
                        const objLoader = new OBJLoader();
                        mesh = objLoader.parse(new TextDecoder().decode(contents));
                        break;

                    default:
                        return;
                }

                if (geometry) {
                    geometry.computeBoundingBox();
                    const center = new Vector3();
                    geometry.boundingBox.getCenter(center);
                    geometry.center();  // Recentre la géométrie autour de l'origine
                    mesh.position.copy(center.multiplyScalar(-0.3));  // Repositionne le mesh pour maintenir sa position visuelle
                }
                // if (mesh.geometry) {
                //     const boundingBox = new Box3().setFromObject(mesh);
                //     const center = new Vector3();
                //     boundingBox.getCenter(center);
                //     mesh.position.sub(center);  // Recentrer l'objet en soustrayant le centre
                //   }

                //   mesh.castShadow = true;

                setModel(mesh);
            };

            if (fileType === 'obj') {
                reader.readAsText(file);
            } else {
                reader.readAsArrayBuffer(file);
            }
        }

    }, [file, fileType]);

    const RotatingModel = ({ model }) => {
        const modelRef = useRef();
        const [paused, setPaused] = useState(false);

        const handlePointerDown = () => {
            setPaused(true);
        };

        const handlePointerUp = () => {
            setPaused(false);
        };

        useFrame(() => {
            if (modelRef.current && !paused) {
                modelRef.current.rotation.z += 0.01;  // Rotation sur l'axe Y
            }
        });

        return model ? <primitive
            object={model}
            ref={modelRef}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerOut={handlePointerUp} /> : null;
    };




    if (!model) {
        return <p>Loading model...</p>;
    }

    return (
        <>

            <div className='scene-container'>
                <Canvas style={{ width: '100%', height: '100%' }}
                    shadows
                    orthographic
                    camera={{ position: [0, -500, 0], fov: 80, near: 0.1, far: 5000, scale: 0.5 }}
                    resize={{ scroll: true, debounce: { scroll: 100, resize: 0 } }}>
                    <ambientLight intensity={0.7} />
                    <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, -5]} castShadow />
                    <Environment preset="city" background blur={1} />
                    <ContactShadows resolution={512} position={[0, -0.8, 0]} opacity={1} scale={10} blur={2} far={0.8} />
                    <directionalLight
                        castShadow
                        position={[5, 5, 5]}
                        intensity={20}
                        shadow-mapSize-width={2048}
                        shadow-mapSize-height={2048}
                    />
                    <RotatingModel model={model} />
                    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1, 0]} receiveShadow>
                        <planeGeometry args={[100, 100]} />
                        <shadowMaterial opacity={0.5} />
                    </mesh>
                    <OrbitControls
                        minDistance={500}  // Distance minimale de la caméra
                        maxDistance={500} // Distance maximale de la caméra
                    />
                </Canvas>
            </div>

        </>
    );
};



export default ModelViewer;
