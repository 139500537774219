import logo from '../Assets/logo.png';
import '../Styles/App.css';
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Lasts from './Lasts.js';
import Users from './AuthUsers/Users.js';
import Account from './Account.js';
import Analytics from './Analytics.js';
import Products from './Products.js';
import Faqs from './Faqs.js';
import Plans from './Plans.js'
import { APIMainRequestGET } from '../Manager/CommManager.js';

const AuthCallback = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    // const searchParams = useSearchParams();
    const [connected, setConnected] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();



    useEffect(() => {


        console.log('AuthCallback -> useEffect -> Get URL Param : ');



    }, []);

    return (

        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    AuthCallback
                </p>
            </header>
        </div>
    );
};

export default AuthCallback;