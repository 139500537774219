import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled } from '@mui/material/styles';
import {
    Accordion, AccordionActions, AccordionSummary, AccordionDetails,
    InputLabel, TextField, Box, MenuItem,
    FormControl, Button, Stack, Paper, Typography,
    Fade, Modal, Backdrop, Stepper, Step, StepLabel, StepContent
} from '@mui/material';

import {
    ExitToApp, ImageOutlined, PictureAsPdfOutlined, ViewInAr, ExpandMore,
    WarningAmberOutlined, Folder, CheckOutlined, Close,
    ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIMainRequestPOST, APIMainRequestGET } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';
import LastsCatManager from '../Components/LastsCatManager';
import StandardManager from '../Components/StandardManager';


const Account = ({ getWitchPage }) => {
    // const navigation = useNavigate();


    return (
        <Box sx={{ flexGrow: 1, flexDirection: 'column', paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
            <Typography sx={{
                borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 2
            }} variant="h4">Settings</Typography>
            <Accordion
                sx={{
                    borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                    borderWidth: 1, padding: 1, borderRadius: 1, marginTop: 2,
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: blue[700] }} />}
                    aria-controls="panel2-content"
                    id="Cat-Last-Manager"
                    variant="h6"
                    sx={{ backgroundColor: grey[100], borderRadius: 1 }}
                >Manage Last category
                </AccordionSummary>
                <AccordionDetails>
                    <LastsCatManager />
                </AccordionDetails>
            </Accordion>

            <Accordion
                sx={{
                    borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                    borderWidth: 1, padding: 1, borderRadius: 1, marginTop: 2,
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: blue[700] }} />}
                    aria-controls="panel2-content"
                    id="Standard-Manager"
                    variant="h6"
                    sx={{ backgroundColor: grey[100], borderRadius: 1 }}
                >Manage size standard
                </AccordionSummary>
                <AccordionDetails>
                    <StandardManager />
                </AccordionDetails>
            </Accordion>

        </Box>
    );
};

const styleModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};

export default Account;