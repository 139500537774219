import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled } from '@mui/material/styles';
import {
    Card, CardHeader, Avatar, CardMedia, IconButton,
    CardContent, CardActions, Box, Collapse, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, Button, Stack, Paper, Typography, Slide, CircularProgress,
    Fade, Modal, Backdrop, Stepper, Step, StepLabel, StepContent,
    colors
} from '@mui/material';

import {
    Man, Woman, Share, ViewInAr, ThreeDRotation,
    WarningAmberOutlined, Folder, CheckOutlined, Close,
    ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded,
    BorderStyle, ExpandMore,
} from '@mui/icons-material';
import Sheet from '@mui/joy/Sheet'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIMainRequestPOST, APIMainRequestGET } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';
import { DateAndTime, DateOnly, TimeOnly, DateAndTineNoUI } from '../Manager/UnixTimestampToDate';
import shadows from '@mui/material/styles/shadows';
import lastSizing from '../Assets/lastSizing.png'

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#f1f1f1',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    margin: 5,
    color: theme.palette.text.secondary,
  }));


const DetailsLasts = ({ data, closeModalRefreshList }) => {

    const [loading, setLoading] = React.useState(false);


    const [getLastCatDetail, setLastCatDetail] = useState()

    const [openModal, setOpenModal] = React.useState(false);
    const [getRawFile, setRawFile] = useState()

    const [getFile, setFile] = useState()
    const [getFileLink, setFileLink] = useState()
    const [getFileName, setFileName] = useState()
    const [getFileType, setFileType] = useState()

    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const SetAvatar = ({ value }) => {
        const getFirstValue = value.slice(0, 3)

        return (
            <Avatar sx={{ bgcolor: blue[700], textTransform: 'capitalize' }} aria-label="Brand">
                {getFirstValue}
            </Avatar>
        )
    }
    const SetGender = ({ value }) => {
        return (<div ><u>Model</u> : {value.model} - <u>Gender</u> :{value.gender}</div>)
    }

    const SetBrand = ({ value }) => {
        return (<div ><b>{value.brand}</b></div>)
    }

    const [openDialog, setOpenDialog] = React.useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseAddLast = () => setOpenDialog(false);

    useEffect(() => {
        console.log('DetailsLasts -> useEffect');
        console.log('DetailsLasts -> useEffect -> ID :' + data);


    }, []);

    return (
        <Paper sx={styleAddLast}>
            <Box sx={{ flexDirection: 'row', minWidth: 500 }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {loading === true ? <div><CircularProgress color="inherit" /></div> :
                    <Card sx={{ height: '100%' }} >
                        <CardHeader
                            avatar = {
                                <SetAvatar value={data.brand} />
                            }
                            action = {
                                <IconButton aria-label="settings">
                                    <Close onClick={() => { closeModalRefreshList() }} />
                                </IconButton>
                            }
                            title={<SetBrand value={data} />}
                            subheader={<SetGender value={data} />}
                        />
                        <CardContent>
                            <StlViewer
                                width={300}
                                height={300}
                                groundColor='rgb(155, 155, 155)'
                                objectColor='rgb(50, 255, 255)'
                                skyboxColor='rgb(225, 225, 225)'
                                gridLineColor='rgb(0, 0, 0)'
                                lightColor='rgb(255, 255, 255)'
                                orbitControls
                                shadows
                                url={data.linkPly !== "" ? data.linkPly : "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"}
                            />
                            <ThreeDRotation sx={{ position: 'absolute', right: 20 }} />
                            <Typography variant="body2" color="text.secondary" sx={{ marginTop: 3 }}>
                                <b>S/N : </b>{data.sn}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <b>Created : </b>{data.updateDate !== null || !data.updateDate ? DateAndTineNoUI(data.entryDate) : '-'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <b>Updated : </b>{data.updateDate !== null || !data.updateDate ? DateAndTineNoUI(data.updateDate) : '-'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <b>Description :</b><br />
                                {data.description}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            {/* <IconButton aria-label="add to favorites">
                                <Favorite />
                            </IconButton>
                            <IconButton aria-label="share">
                                <Share />
                            </IconButton> */}
                            {/* <ExpandMoreContent
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                sx={{ color: grey[700] }}
                            >
                                <ExpandMore />
                            </ExpandMoreContent> */}
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent >
                                <Typography variant="body2" color="text.secondary">
                                    <b>Sizing :</b>
                                </Typography>
                                <CardMedia
                                    component="img"
                                    image={lastSizing}
                                    alt={'lastSizing.png'}
                                />
                                <Grid
                                    container
                                    rowSpacing={1}
                                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                    sx={{ width: '100%', padding:2 }}
                                >
                                    <Grid xs={6}><Item>Lenght</Item></Grid>
                                    <Grid xs={6}><Item>{data.lenght}</Item></Grid>

                                    <Grid xs={6}><Item>Width</Item></Grid>
                                    <Grid xs={6}><Item>{data.width}</Item></Grid>
                                    
                                    <Grid xs={6}><Item>Ball girth</Item></Grid>
                                    <Grid xs={6}><Item>{data.ballGirth}</Item></Grid>

                                    <Grid xs={6}><Item>Heel girth</Item></Grid>
                                    <Grid xs={6}><Item>{data.heelGirth}</Item></Grid>

                                    <Grid xs={6}><Item>Heel</Item></Grid>
                                    <Grid xs={6}><Item>{data.heel}</Item></Grid>

                                    <Grid xs={6}><Item>Last lenght</Item></Grid>
                                    <Grid xs={6}><Item>{data.lastLenght}</Item></Grid>

                                    <Grid xs={6}><Item>Last width</Item></Grid>
                                    <Grid xs={6}><Item>{data.lastWidth}</Item></Grid>
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </Card>
                }
            </Box>
        </Paper>
    );
};

const styleAddLast = {
    position: 'absolute',
    top: '5%',
    left: '25%',
    // transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: 500,
    height: '88%',
    bgcolor: '#ffffff',
    shadows: 'none',

    overflow: 'auto',
};

export default DetailsLasts;