import { Height } from "@mui/icons-material"
import { grey } from "@mui/material/colors"

const Styles = {

  AppItemsSelected: {
    backgroundColor: '#265F5A26',
    fontSize: 30,
    fontWeight: 400,
    color: '#265F5A',
    borderRadius: '13px',
  },
  AppItems: {
    backgroundColor: '#ffffff',
    fontSize: 30,
    fontWeight: 100,
    color: '#1c1c1c',
    borderRadius: '13px',
    
  },
  SectionStyleMinus: {
    height: 30,
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    margin: 5,
    width: '50%',
    alignSelf: 'center'
  },

}

export default { Styles }